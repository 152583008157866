.Header {
  height: 80px;
  margin: 0;
  display: flex;
  justify-content: left;
  
}

.Header-logo {
  width: 150px;
  margin-left: 130px;
  position: relative;
}

.wrapper {
  display: grid;
}

.Header, .Skip {
  grid-area: 1 / 1;
}

.Skip {
  display: flex;
  justify-content: flex-end;
  float: right;
  position: relative;
  margin: 10px 50px 0 0;
}

.Skip a {
  color: #000 !important;
  font-weight: bold;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .Header {
    justify-content: center;
  }
  .Header-logo {
    margin-left: 0px;   
  }
  .Skip {
    margin: 10px 20px 0 0;
  }
}

@media screen and (min-width: 1400px) {
  .Header-logo {
    margin-left: 170px;
  }
}
