.App-background { 
  width: 100%;
  height: 100% !important;
  background-image: url(../background.jpg);
  background-repeat: repeat-y; 
  background-size: cover;
  display: block;
  position: absolute;
  overflow: auto;
}

.App-background::before {
  content: "";
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

a {
  color: #3c4044 !important;
}

.Separator {
  margin-bottom: 500px;
}

.App-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.App-card {
  border-radius: 10px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 1) !important;
  width: 28rem;
  height: 100% !important;
}

.App-card-body {
  margin: 2px 10px;
  padding: 10px;
}

.App-card-header {
  background-color: #393c92;
  padding: 10px 5px;
  color: #ffffff;
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4) !important;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.App-title, .App-subtitle, .reason-why, .App-button, .btn.nohover:hover, .Job-button, .Skip-button, .multiple-text {
  font-family: 'Poppins', sans-serif;
  color: #3c4044 !important;
}

.Form-label {
  text-align: left !important;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #3b3f43;
}

.Form-combo {
  color: #3b3f43;
  width: 100%;
  height: 40px;
  border: 1px solid #33373a;
  border-radius: 2px;
  background-color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  margin-top: 8px;
  padding: 0 16px;
}

.Form-box {
  height: 40px;
  display: block;
  width: 100%;
  border: 1px solid #767676;
  border-radius: 2px;
  background-color: #fff;
  padding: 0 16px;
}

.Form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Form-Date-box {
  height: 40px;
  width: 33%;
  border: 1px solid #767676;
  border-radius: 2px;
  background-color: #fff !important;
  padding: 0 16px;
}

.Small-Text {
  font-size: 10px;
}

.Bold-Text {
  font-size: 16px;
  font-weight: 700;
}

.DoNotSellPopUp, .DoNotSellPopUp:active, .DoNotSellPopUp:focus, .DoNotSellPopUp:hover {
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  color: #FFF !important;
  background: linear-gradient(225deg, #5498B4 0%, #62C046 100%);
  border-color: transparent !important;
  width: 100% !important;
  height: 55px;
}

.Form-continue, .Form-continue:active, .Form-continue:focus, .Form-continue:hover {
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  color: #FFF !important;
  background: linear-gradient(225deg, #5498B4 0%, #62C046 100%);
  border-color: transparent !important;
  width: 100% !important;
  height: 50px;
}

.Select-option {
  width: 100%;
  border-radius: 20px;
  background-color: #d0dbe0;
  color: #3c4044 !important;
  font-weight: 600;
  height: 50px;
  padding: 10px;
  text-align: center;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.Skip-button {
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  color: #3c4044 !important;
  font-weight: 500;
}

.Skip-button:hover {
  text-decoration: underline;
}

.App-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: #5e666d !important;
}

.App-question {
  font-size: 18px;
  font-weight: 600;
  color: #3c4044;
}

.Job-image {
  width: 60px;
  height: 60px;
  border: 2px solid #c9d3d7;
}

.Job-arrow {
  width: 60px;
}

.Job-card {
  border: 2px solid #c9d3d7;
  border-radius: 8px;
  margin-bottom: 20px;
}

.Job-partner {
  color: #ffffff;
  background-color: #5498b4;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 6px;
  height: 22px;
  width: 105px;
  border-radius: 5px;
}

.Job-title {
  font-family: 'Poppins', sans-serif;
  text-decoration: underline;
  font-size: 16px;
  color: #3c4044 !important;
  font-weight: 600;
}

.Job-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: #3c4044 !important;
  font-weight: 600;
}

.control-top {
  margin-top: 120px;
}

.control-bottom {
  margin-top: -100px;
}

.control-email {
  margin-top: 150px;
}

.alert-text {
  font-size: 14px;
  font-weight: 400;
}

.App-title {
  font-weight: 700;
  line-height: 20px;
  font-size: 26px;
  margin-bottom: 10px !important;
}

.Auto-Insu-title {
  font-weight: 700;
  line-height: 25px;
  font-size: 26px;
  margin-bottom: 10px !important;
}

.Quote-Page-Title {
  font-weight: 700;
  line-height: 25px;
  font-size: 26px;
  padding: 10px 0 !important;
}

.laHsXx {
  position: relative;
  height: 42px;
  margin: 10px 0 10px 0;
}

.App-progressbar {
  border-radius: 0;
}

.progress-bar {
  background-color: #30c42c !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #30c42c !important;
  border:none !important;
}
.custom-control-label {
  font-family: 'Poppins', sans-serif;
  color: #3c4044 !important;
  font-size: 18px;
  padding: 3px;
  margin-left: 3px;
  text-align: center;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  -webkit-appearance: none;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control-input, .custom-control-input::before, .custom-control-input::after, .custom-control-input:focus, .custom-control-input:active {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.Checkbox-container {
  margin-left: 5px;
}

.agreement {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Text-container {
  display: flex;
  justify-content: center;
  align-items:flex-start;
}

.separator {
  box-shadow: 0px 1px 1px #888888;
  margin-top: 1px;
}

.Form-select {
  text-align: left;
  margin-left: 20px;
}

.img-rounded {
  border-radius: 25px 0 0 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4) !important;
  height: 535px;
}

.icon {
  width: 96px;
  height: 96px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
}

.line-spacing {
  line-height: 1.6;
}

.policy {
  text-align: justify;
  font-size: 10px;
}

.policy-footer {
  text-align: justify;
  font-size: 16px;
}

.checkbox-bg {
  width: 150px;
  background-color: #6decc3;
  border-radius: 10px;
}

.custom-file-input:focus~.custom-file-label {
  border-color: none;
  box-shadow: none;
}

.checkbox-hidden {
  display: none;
}



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .App-card {
    width: 95%;
    margin: 0 auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .App-card {
    width: 95%;
    margin: 0 auto;
  }

  .Footer-social-icons li {
    height: 35px;
  }

  .Footer-social-icons i {
    width: 25px;
  }

  .subscribe {
    margin-left: 10px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .App-card {
    width: 95%;
    margin: 0 auto;
  }

  .App-progressbar {
    border-radius: 0;
  }

  .perk-desc {
    text-align: center;
  }

  .Footer-logo {
    width: 80%;
  }

  .Links-content {
    width: 20rem !important;
  }

  .Links li {
    display: block;
  }

  .subscribe {
    margin-left: 15px;
  }
}

.CursorPointer {
  cursor: pointer;
}

.LineHeight {
  line-height: 28px;
}

.Text-Center {
  text-align: center !important;
}

.Skip-To-Jobs {
  display: flex;
  justify-content: flex-end;
  float: right;
  position: relative;
  font-size: 16px;
}

.Tcpa-Px {
  text-align: justify;
  font-size: 12px;
}
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .App-card {
    width: 95%;
    margin: 0 auto;
  }
}